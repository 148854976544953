import React from 'react';
import './About.scss';

const About = () =>{
    return (
        <>
            <section className="about" id="about">

                <h2 className="about__title">About</h2>

                <div className="about__detail">
                    <p className="about__text">A <strong>full-stack web developer</strong> based in Toronto.</p>
                    <p className="about__text">As our dependency on technology is gradually increasing, it is exciting for me to take a part in that advancement. With an enthusiasm towards technology and a passion to leverage my problem solving skills, I wish to create a multidisciplinary web and app for the users.</p>
                    <p className="about__text">I enjoy getting involved in every steps of product development, from brainstorming, to the design, and to the web.</p>
                </div>

            </section>

            
        </>
    )
}

export default About;