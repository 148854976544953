import React from 'react';
import { Link } from 'react-router-dom';
import './Project.scss';

import onedrobe from '../../Assets/onedrobe/onedrobe.png';
import brainFlix from '../../Assets/brainFlix/page.png';
import mrkt from '../../Assets/mrkt/mrkt.png';
import brainpacman from '../../Assets/brainpacman/logo.png';

import react from '../../Assets/skill/react.png';
import sass from '../../Assets/skill/sass.png';
import node from '../../Assets/skill/nodejs.png';
import express from '../../Assets/skill/expressjs-ar21.svg';
import firebase from '../../Assets/skill/firebase.png';
import html from '../../Assets/skill/html-5.png';
import javascript from '../../Assets/skill/javascript (1).png';

const Project = () => {

    return (
        <section className="project" id="project">

            <h2 className="project__title">Projects</h2>

                <div className="project__container">
                    <Link to="/project/onedrobe" className="project__link">
                        <div className="project__main">
                            <img className="project__image" src={onedrobe} alt="onedrobe mainpage"/>
                            <div className="project--flex">
                                <div className="project__detail">
                                    <h3 className="project__name">onedrobe</h3>
                                    <p className="project__description">An essential fashion app that helps users make rational consumption by collecting and comparing all the brands in one place.</p>
                                    <div className="project__stack">
                                        <img src={react} alt="tech-stack"/>
                                        <img src={sass} alt="tech-stack"/>
                                        <img src={node} alt="tech-stack"/>
                                        <img src={express} alt="tech-stack"/>
                                        <img src={firebase} alt="tech-stack"/>
                                    </div>
                                </div>                                
                            </div>
                        </div>                        
                    </Link>
                </div>

                <div className="project__container">
                    <Link to="/project/brainFlix" className="project__link">
                        <div className="project__main">
                            <img className="project__image" src={brainFlix} alt="onedrobe mainpage"/>
                            <div className="project--flex">
                                <div className="project__detail">
                                    <h3 className="project__name">brainFlix</h3>
                                    <p className="project__description">A video platform where users can post new item and comment on each video.</p>
                                    <div className="project__stack">
                                        <img src={react} alt="tech-stack"/>
                                        <img src={sass} alt="tech-stack"/>
                                        <img src={node} alt="tech-stack"/>
                                        <img src={express} alt="tech-stack"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="project__container">
                    <Link to="/project/mrkt" className="project__link">
                        <div className="project__main">
                            <img className="project__image" src={mrkt} alt="onedrobe mainpage"/>
                            <div className="project--flex">
                                <div className="project__detail">
                                    <h3 className="project__name">MRKT</h3>
                                    <p className="project__description">A potential solution for SMB groceries and their local suppliers during COVID-19 crisis. They can consolidate all their offerings and can be a one-stop shop to check available local supplies.</p>
                                    <div className="project__stack">
                                        <img src={react} alt="tech-stack"/>
                                        <img src={sass} alt="tech-stack"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="project__container">
                    <Link to="/project/brainpacman" className="project__link">
                        <div className="project__main">
                            <img className="project__image" src={brainpacman} alt="onedrobe mainpage"/>
                            <div className="project--flex">
                                <div className="project__detail">
                                    <h3 className="project__name">Brain-Pac-Man</h3>
                                    <p className="project__description">A pacman game that is adjusted specifically to BrainStation Web Development Program. If you don't consume the knowledge of technology stacks, the education team will be after you!</p>
                                    <div className="project__stack">
                                        <img src={html} alt="tech-stack"/>
                                        <img src={sass} alt="tech-stack"/>
                                        <img src={javascript} alt="tech-stack"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

        </section>
    )

}

export default Project;