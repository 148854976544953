import React from 'react';
import { Link } from 'react-router-dom';
import '../Onedrobe/Onedrobe.scss';

import logo from '../../Assets/brainFlix/logo.svg';
import back from '../../Assets/back-arrow.svg';
import demo1 from '../../Assets/brainFlix/demo.png';
import demo2 from '../../Assets/brainFlix/demo2.png';
import demo3 from '../../Assets/brainFlix/demo3.png';

class BrainFlix extends React.Component {

    state={
        open: false,
    }

    openDetail = () =>{
        const open = this.state.open;
        this.setState({
            open: !open
        })
    }

    componentDidMount() {
        document.addEventListener("click", this.closeHandle);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.closeHandle);
    }

    closeHandle = (e) => {
        if (e.target.classList[0] !== "project-detail__open") {
            this.setState({
                open: false
            })
        }
    }
    render () {
        return (
            <section className="project-detail">
                <Link to="/" className="project-detail__back">
                    <img src={back} alt="back to projects" />
                    <p>back to project lists..</p>
                </Link>
                <div className="project-detail--flex">
                    <img className="brainflix" src={logo} alt="brainFlix logo"/>
                    <div>
        
                        {/* <h3 className="project-detail__title">onedrobe</h3> */}
        
                        <ul className="project-detail__stack">
                            <li>React</li>
                            <li>SCSS</li>
                            <li>Node.js</li>
                            <li>Express.js</li>
                        </ul>
        
                        <div className="project-detail__button">
                            <button className="project-detail__open project-detail__btn btn" onClick={this.openDetail}>view detail</button>
                            <a className="project-detail__link" href="https://github.com/jshin26/JessicaShin-OneDrobe" target="_blank" rel="noopener noreferrer">
                                <button className="project-detail__btn btn">visit brainFlix</button>
                            </a>
                        </div>
    
                        {this.state.open &&
                            <section className="open">

                                <div className="open__box">
                                    <h2 className="open__title">01. Demo</h2>
                                    <div className="open__demo">
                                        <img src={demo1} alt="brainFlix demo" />
                                        <img src={demo2} alt="brainFlix demo" />
                                        <img src={demo3} alt="brainFlix demo" />
                                    </div>
                                </div>
        
                                <div className="open__box">
                                    <h2 className="open__title">02. Front-End Functionality</h2>
                                    <p className="open__text">Using the API and state, data is passed down as props to generate side videos, all main video content including comments</p>
                                    <p className="open__text">There are 3 routes of Home/Video Player Page, Video Upload Page, and Side vidoe with video id</p>
                                    <p className="open__text">Clicking on a video in the "Next Video" list goese to the Video Details Page for the selected video and display all the information for the video such as likes, view, author, description, etc.</p>
                                </div>
    
                                <div className="open__box">
                                    <h2 className="open__title">03. Back-End Functionality</h2>
                                    <p className="open__text">The end-point and reponse structure of back-end server contains GET/video, GET/video/:id, POST/video</p>
                                    <p className="open__text">Submitting a new video from the form (Upload Page) POSTs to the API.</p>
                                </div>                        
    
                            </section>
                        }
        
                    </div>
                </div>
    
            </section>
        )
    }

}

export default BrainFlix;