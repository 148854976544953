import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './Main.scss';

import About from '../About/About';
import Project from '../Project/Project';
import Onedrobe from '../Onedrobe/Onedrobe';
import BrainFlix from '../BrainFlix/BrainFlix';
import Mrkt from '../Mrkt/Mrkt';
import BrainPacman from '../BrainPacman/BrainPacman';

import Skill from '../Skill/Skill';

const Main = () => {

    return (
        <BrowserRouter>
            <section className="main">
    
                <About />
                <Skill />
                <Switch>
                    <Route path="/" exact component={Project} />
                    <Route path="/project/onedrobe" exact component={Onedrobe} />
                    <Route path="/project/brainFlix" exact component={BrainFlix} />
                    <Route path="/project/mrkt" exact component={Mrkt} />
                    <Route path="/project/brainpacman" exact component={BrainPacman} />
                </Switch> 

                
    
            </section>
        </BrowserRouter>
    )

}

export default Main;