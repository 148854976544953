import React from 'react';
import './Skill.scss';

import html from '../../Assets/skill/html-5.png';
import semantic from '../../Assets/skill/semantic-web.png';
import css from '../../Assets/skill/css.png';
import sass from '../../Assets/skill/sass.png';
import responsive from '../../Assets/skill/responsive-design.png';
import javascript from '../../Assets/skill/javascript (1).png';
import dom from '../../Assets/skill/javascript (2).png';
import react from '../../Assets/skill/react.png';
import spa from '../../Assets/skill/sitemap.png';
import node from '../../Assets/skill/nodejs.png';
import express from '../../Assets/skill/expressjs-ar21.svg';
import api from '../../Assets/skill/api.png';
import axios from '../../Assets/skill/axios-ar21.svg';
import auth from '../../Assets/skill/authentication.png';
import firebase from '../../Assets/skill/firebase.png';
import deploy from '../../Assets/skill/deployment.png';
import heroku from '../../Assets/skill/heroku.png';

class Skill extends React.Component {
    
    render () {
        return (

            <section className="skill" id="skill">
    
                <h2 className="skill__title">Skill</h2>
    
                <div className="skill--flex">
                    <div className="skill__box">
                        <img className="skill__stack" src={html} alt="skill"/>
                        <div className="skill__detail">
                            <p>semantic markup</p>
                            <img src={semantic} alt="semantic web"/>
                        </div>
                    </div>
                    <div className="skill__box">
                        <img className="skill__stack" src={css} alt="skill"/>
                        <div className="skill__detail">
                            <p>responsive design</p>
                            <img src={responsive} alt="responsive web"/>
                        </div>
                    </div>
                    <div className="skill__box mobile">
                        <img className="skill__stack" src={sass} alt="skill"/>
                    </div>
                    <div className="skill__box">
                        <img className="skill__stack" src={javascript} alt="skill"/>
                        <div className="skill__detail">
                            <p>DOM</p>
                            <img src={dom} alt="dom"/>
                        </div>
                    </div>
                    <div className="skill__box">
                        <img className="skill__stack" src={react} alt="skill"/>
                        <div className="skill__detail">
                            <p>SPA</p>
                            <img src={spa} alt="spa"/>
                        </div>
                    </div>
                    <div className="skill__box">
                        <img className="skill__stack" src={node} alt="skill"/>
                        <div className="skill__detail">
                            <p>express.js</p>
                            <img src={express} alt="express"/>
                        </div>
                    </div>
                    <div className="skill__box">
                        <img className="skill__stack" src={api} alt="skill"/>
                        <div className="skill__detail">
                            <p>axios</p>
                            <img src={axios} alt="axios"/>
                        </div>
                    </div>
                    <div className="skill__box tablet">
                        <img className="skill__stack" src={auth} alt="skill"/>
                        <div className="skill__detail">
                            <p>firebase</p>
                            <img src={firebase} alt="firebase"/>
                        </div>
                    </div>
                    <div className="skill__box tablet">
                        <img className="skill__stack" src={deploy} alt="skill"/>
                        <div className="skill__detail">
                            <p>Heroku, Netlify</p>
                            <img src={heroku} alt="heroku"/>
                        </div>
                    </div>
                    <div className="skill__box mobile">
                        <img className="skill__stack" src={firebase} alt="skill"/>
                    </div>
                    <div className="skill__box mobile">
                        <img className="skill__stack" src={heroku} alt="skill"/>
                    </div>

                </div>
    
            </section>
    
        )
    }
}

export default Skill;