import React from 'react';
import { Link } from 'react-router-dom';
import '../Onedrobe/Onedrobe.scss';

import logo from '../../Assets/brainpacman/page.jpg';
import back from '../../Assets/back-arrow.svg';
import demo1 from '../../Assets/brainpacman/demo1.png';
import demo2 from '../../Assets/brainpacman/demo2.png';
import demo3 from '../../Assets/brainpacman/demo3.png';

class BrainPacman extends React.Component {

    state={
        open: false,
    }

    openDetail = () =>{
        const open = this.state.open;
        this.setState({
            open: !open
        })
    }

    componentDidMount() {
        document.addEventListener("click", this.closeHandle);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.closeHandle);
    }

    closeHandle = (e) => {
        if (e.target.classList[0] !== "project-detail__open") {
            this.setState({
                open: false
            })
        }
    }
    render () {
        return (
            <section className="project-detail">
                <Link to="/" className="project-detail__back">
                    <img src={back} alt="back to projects" />
                    <p>back to project lists..</p>
                </Link>
                <div className="project-detail--flex">
                    <img className="brainflix" src={logo} alt="BrainPacman logo"/>
                    <div>
        
                        {/* <h3 className="project-detail__title">onedrobe</h3> */}
        
                        <ul className="project-detail__stack">
                            <li>HTML</li>
                            <li>SCSS</li>
                            <li>Javascript</li>
                        </ul>
        
                        <div className="project-detail__button">
                            <button className="project-detail__open project-detail__btn btn" onClick={this.openDetail}>view detail</button>
                            <a className="project-detail__link" href="https://brainpacman.netlify.app/" target="_blank" rel="noopener noreferrer">
                                <button className="project-detail__btn btn">visit Brain-Pac-Man</button>
                            </a>
                        </div>
    
                        {this.state.open &&
                            <section className="open">

                                <div className="open__box">
                                    <h2 className="open__title">01. Demo</h2>
                                    <div className="open__demo">
                                        <img src={demo1} alt="brainpacman demo" />
                                        <img src={demo2} alt="brainpacman demo" />
                                        <img src={demo3} alt="brainpacman demo" />
                                    </div>
                                </div>
        
                                <div className="open__box">
                                    <h2 className="open__title">02. Idea</h2>
                                    <p className="open__text">We initially came up with two ideas; Trivia questions and pac-man game. Since it is a pair-programming, we decided to take a challenge, and started to search all relevant resources, tutorials and demos.</p>
                                </div>
    
                                <div className="open__box">
                                    <h2 className="open__title">03. Challenges</h2>
                                    <p className="open__text">Because we referred to a lot of resources, we added some twists of our own, such as three different levels and emojis. Adjusting ghost features on each level and working with setTimeout/setInterval were also very hard to manage.</p>
                                </div>

                                <div className="open__box">
                                    <h2 className="open__title">04. Successes</h2>
                                    <p className="open__text">In the end, we could implement additional features to the game, and customize the game characters. The ghosts were able to move in random directions and change speed depending on the levels. We also added background music to add some joy for the users.</p>
                                </div> 
    
                            </section>
                        }
        
                    </div>
                </div>
    
            </section>
        )
    }

}

export default BrainPacman;