import React from 'react';
import { Link } from 'react-router-dom';
import '../Onedrobe/Onedrobe.scss';

import logo from '../../Assets/mrkt/logo.jpg';
import back from '../../Assets/back-arrow.svg';

import uxmockup1 from '../../Assets/mrkt/mockup1.png';
import uxmockup2 from '../../Assets/mrkt/mockup2.png';
import uxmockup3 from '../../Assets/mrkt/mockup3.png';
import data1 from '../../Assets/mrkt/data1.png';
import data2 from '../../Assets/mrkt/data2.png';
import data3 from '../../Assets/mrkt/data3.png';
import data4 from '../../Assets/mrkt/data4.png';

import demo from '../../Assets/mrkt/mrkt.png';

class Mrkt extends React.Component {

    state={
        open: false,
    }

    openDetail = () =>{
        const open = this.state.open;
        this.setState({
            open: !open
        })
    }

    componentDidMount() {
        document.addEventListener("click", this.closeHandle);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.closeHandle);
    }

    closeHandle = (e) => {
        if (e.target.classList[0] !== "project-detail__open") {
            this.setState({
                open: false
            })
        }
    }
    render () {
        return (
            <section className="project-detail">
                <Link to="/" className="project-detail__back">
                    <img src={back} alt="back to projects" />
                    <p>back to project lists..</p>
                </Link>
                <div className="project-detail--flex">
                    <img className="project-detail__logo" src={logo} alt="onedrobe logo"/>
                    <div>
        
                        {/* <h3 className="project-detail__title">MRKT</h3> */}
        
                        <ul className="project-detail__stack">
                            <li>React</li>
                            <li>SCSS</li>
                        </ul>
        
                        <div className="project-detail__button">
                            <button className="project-detail__open project-detail__btn btn" onClick={this.openDetail}>view detail</button>
                            <a className="project-detail__link" href="https://github.com/jshin26/EY-hackthon-team18" target="_blank" rel="noopener noreferrer">
                                <button className="project-detail__btn btn">visit MRKT</button>
                            </a>
                        </div>
    
                        {this.state.open &&
                            <section className="open">

                                <div className="open__box">
                                    <h2 className="open__title">01. Demo</h2>
                                    <div className="open__demo">
                                        <img src={demo} alt="MRKT demo" />
                                    </div>
                                </div>
        
                                <div className="open__box">
                                    <h2 className="open__title">02. Brainstorming</h2>
                                    <p className="open__text">We discussed on what kind of problems we, as consumers, and business owners are facing. From there, we came up with few different solutions.</p>
                                </div>
    
                                <div className="open__box">
                                    <h2 className="open__title">03. Problem</h2>
                                    <p className="open__text">Small stores have no online presence and have difficulties reaching out to consumers during COVID-19 crisis. </p>
                                </div>
    
                                <div className="open__box">
                                    <h2 className="open__title">04. Decision</h2>
                                        <p className="open__text">We decided to focus on SMB groceries and their local suppliers, as their supply chain can be very spread out and vulnerable to disruption (like a pandemic); they might not have access to all suppliers due to fragmantation. It will be beneficial if they can consolidate all their offerings and one-stop shop for SMB grocers to check available local supplies.</p>
                                </div>

                                <div className="open__box">
                                    <h2 className="open__title">05. Data</h2>
                                    <img className="open__sitemap" src={data1} alt="data"/>
                                    <img className="open__sitemap" src={data2} alt="data"/>
                                    <img className="open__sitemap" src={data3} alt="data"/>
                                    <img className="open__sitemap" src={data4} alt="data"/>
                                </div> 
        
                                <div className="open__box">
                                    <h2 className="open__title">06. UX Design Mockups</h2>
                                    <img className="open__sitemap" src={uxmockup1} alt="UX design mockup"/>
                                    <img className="open__sitemap" src={uxmockup2} alt="UX design mockup"/>
                                    <img className="open__sitemap" src={uxmockup3} alt="UX design mockup"/>
                                </div>                           
    
                            </section>
                        }
        
                    </div>
                </div>
    
            </section>
        )
    }

}

export default Mrkt;