import React from 'react';
import { Link } from 'react-router-dom';
import './Onedrobe.scss';

import logo from '../../Assets/onedrobe/logo.png';
import back from '../../Assets/back-arrow.svg';
import sitemap from '../../Assets/onedrobe/sitemap.png';
import demo1 from '../../Assets/onedrobe/demo1.png';
import demo2 from '../../Assets/onedrobe/demo2.png';
import demo3 from '../../Assets/onedrobe/demo3.png';
import demo4 from '../../Assets/onedrobe/demo4.png';
import demo5 from '../../Assets/onedrobe/demo5.png';
import demo6 from '../../Assets/onedrobe/demo6.png';
import demo7 from '../../Assets/onedrobe/demo7.png';
import demo8 from '../../Assets/onedrobe/demo8.png';
import demo9 from '../../Assets/onedrobe/demo9.png';
import demo10 from '../../Assets/onedrobe/demo10.png';
import demo11 from '../../Assets/onedrobe/demo11.png';

class Onedrobe extends React.Component {

    state={
        open: false,
    }

    openDetail = () =>{
        const open = this.state.open;
        this.setState({
            open: !open
        })
    }

    componentDidMount() {
        document.addEventListener("click", this.closeHandle);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.closeHandle);
    }

    closeHandle = (e) => {
        if (e.target.classList[0] !== "project-detail__open") {
            this.setState({
                open: false
            })
        }
    }
    render () {
        return (
            <section className="project-detail">
                <Link to="/" className="project-detail__back">
                    <img src={back} alt="back to projects" />
                    <p>back to project lists..</p>
                </Link>
                <div className="project-detail--flex">
                    <img className="project-detail__logo" src={logo} alt="onedrobe logo"/>
                    <div>
        
                        {/* <h3 className="project-detail__title">onedrobe</h3> */}
        
                        <ul className="project-detail__stack">
                            <li>React</li>
                            <li>SCSS</li>
                            <li>Node.js</li>
                            <li>Express.js</li>
                            <li>Firebase</li>
                        </ul>
        
                        <div className="project-detail__button">
                            <button className="project-detail__open project-detail__btn btn" onClick={this.openDetail}>view detail</button>
                            <a className="project-detail__link" href="https://github.com/jshin26/JessicaShin-OneDrobe" target="_blank" rel="noopener noreferrer">
                                <button className="project-detail__btn btn">visit onedrobe</button>
                            </a>
                        </div>
    
                        {this.state.open &&
                            <section className="open">

                                <div className="open__box">
                                    <h2 className="open__title">01. Demo</h2>
                                    {/* <div className="open__video">
                                        <video controls src={demo} type="video/mp4">
                                            <source src={demo} type="video/mp4"></source>
                                        </video>
                                    </div> */}
                                    <div className="open__demo">
                                        <img src={demo1} alt="onedrobe demo" />
                                        <img src={demo2} alt="onedrobe demo" />
                                        <img src={demo3} alt="onedrobe demo" />
                                        <img src={demo4} alt="onedrobe demo" />
                                        <img src={demo5} alt="onedrobe demo" />
                                        <img src={demo6} alt="onedrobe demo" />
                                        <img src={demo7} alt="onedrobe demo" />
                                        <img src={demo8} alt="onedrobe demo" />
                                        <img src={demo9} alt="onedrobe demo" />
                                        <img src={demo10} alt="onedrobe demo" />
                                        <img src={demo11} alt="onedrobe demo" />
                                    </div>
                                </div>
        
                                <div className="open__box">
                                    <h2 className="open__title">02. Overview</h2>
                                    <p className="open__text">The "onedrobe" will guide the users to make a rational consumption by allowing them to search any fashion products from more than 50 brands with just one click. The "onedrobe" not only facilitates online shopping experience, but also assists the offline shopping by searching through other brands in real time.</p>
                                </div>
    
                                <div className="open__box">
                                    <h2 className="open__title">03. Problem</h2>
                                    <p className="open__text">Now we live in a world where the fashion industry has significant impact, where consumers express themselves through fashion, and where we spend more and more money on fashion products. Since then, shopping has been settled down as a culture of our spending habits.</p>
                                    <p className="open__text">However, the process of shopping is not always as exciting as we expect. Whether shopping online or offline, we compare a lot of similar products based on our own criteria - price, quality, brand, fashion tastes or user reviews. And we sometimes end up finding similar products at a lower price, but after purchase. Especially during the COVID-19 pandemic lock-down, it takes longer to get into the shops due to the line-up and it exposes ourselves to potential danger.</p>
                                    <p className="open__text">The idea of “onedrobe” came from this experience. What if we can compare similar products ofvarious brands on one website? What if we know what we want to try-out and save time and money?</p>
                                </div>
    
                                <div className="open__box">
                                    <h2 className="open__title">04. User Profile</h2>
                                    <ul className="open__list">
                                        <li>consumers
                                            <p className="open__text">Any consumer shopping for apparels can use this application to search through various products and compare all brands for better decisions. It will help them narrow down to the perfect clothing of their choice. When they search for any type of apparels, the website will provide you with the result of all the relevant categories. In order for the users to access easily, the whole UI should be simple and straightforward, including filtering and sort by features.</p>
                                        </li>
                                        <li>Business Owners (Fashion brands)
                                            <p className="open__text">Business owners are also beneficiaries as they can use this application as a marketing platform. If the consumers can compare their products with other brands, it could be a good opportunity to emphasize their strengths.</p>
                                        </li>
                                    </ul>
                                </div>
        
                                <div className="open__box">
                                    <h2 className="open__title">05. Site Map</h2>
                                    <img className="open__sitemap" src={sitemap} alt="site map of onedrobe"/>
                                </div>                           
    
                            </section>
                        }
        
                    </div>
                </div>
    
            </section>
        )
    }

}

export default Onedrobe;