import React from 'react';
import './Header.scss';
import ReactModal from 'react-modal';

import arrow1 from '../../Assets/arrow1.png';
import arrow2 from '../../Assets/arrow2.png';

class Header extends React.Component {

    state = {
        showChatbot: false
    }

    handleOpenChatbot = () => {
        this.setState({
            showChatbot: true
        })
    }
    handleCloseChatbot = () => {
        this.setState({
            showChatbot: false
        })
    }

    render () {
        return (
            <nav className="header">


                <div className="header--btnblock" >
                    <button className="header__button" onClick={this.handleOpenChatbot}>
                        <span className="header--wavy" style={{"--i":1}}>m</span>
                        <span className="header--wavy" style={{"--i":2}}>e</span>
                        <span className="header--wavy" style={{"--i":3}}>n</span>
                        <span className="header--wavy" style={{"--i":4}}>u</span>
                        <img className="header--wavy header__icon" style={{"--i":5}} src={this.state.showChatbot ? arrow1 : arrow2} alt="arrow"/>
    
                    </button>
                </div>

                <ReactModal
                    isOpen={this.state.showChatbot}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.handleCloseChatbot}
                    className="modal"
                    overlayClassName="overlay"
                >
                {/* {this.state.open && */}
                    {/* <> */}
                        <a className="header__list" href="#about" onClick={this.handleCloseChatbot}>About</a>
                        <a className="header__list" href="#skill" onClick={this.handleCloseChatbot}>Skill</a>
                        <a className="header__list" href="#project" onClick={this.handleCloseChatbot}>Project</a>
                        {/* <a className="header__list" href="#contact" onClick={this.handleCloseChatbot}>Contact</a> */}
                    {/* </> */}
                {/* } */}
                </ReactModal>
            </nav>
        )
    }
}

export default Header;