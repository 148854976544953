import React from 'react';
import './Home.scss';
import Typical from 'react-typical';

import jessica from '../../Assets/jess.png';
import github from '../../Assets/github.png';
import linkedin from '../../Assets/linkedin.png';
import mail from '../../Assets/email.png';

const Home = () => {

    return (
        <section className="home">

            <img className="home__image" src={jessica} alt="Jessica Shin"/>

            <div className="home__title">
                <Typical 
                    loop={Infinity}
                    wrapper="h1"                
                    steps={[
                        500,
                        '<h1> JESSICA SHIN <h1>',
                        1000,
                    ]}
                />
            </div>

            <div className="home__text">
                <Typical 
                    loop={Infinity}
                    wrapper="p"                         
                    steps={[
                        2000,
                        'A Toronto-based user-interface design and code enthusiast',
                        5000                         
                    ]}
                />
            </div>

            <div className="home__text">
                <Typical 
                    loop={Infinity}
                    wrapper="p"                         
                    steps={[
                        6000,
                        `I build web with <strong> passion <strong>`,
                        5000                         
                    ]}
                />
            </div>

            <div className="home__contact">
                <a className="home__contact-link" href="https://github.com/jshin26" target="_blank" rel="noopener noreferrer">
                    <img className="home__contact-icon" src={github} alt="github"/>
                </a>
                <a className="home__contact-link" href="https://www.linkedin.com/in/jshin26/" target="_blank" rel="noopener noreferrer">
                    <img className="home__contact-icon" src={linkedin} alt="linkedin"/>
                </a>
                <a className="home__contact-link" href="mailto:hyunjoo940926@gmail.com">
                    <img className="home__contact-icon" src={mail} alt="mail to"/>
                </a>
            </div>
            
        </section>
    )    
};

export default Home;