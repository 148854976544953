import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';

import Home from './Component/Home/Home';
import Header from './Component/Header/Header';
import Main from './Component/Main/Main';

const App = () => {
  return (
    <BrowserRouter>

        <main className="App">

        	<div className="App--left">
            <Home />
            <Header />
          </div>
        
        	<div className="App--right">
            <Main />
            
          </div>

        </main>

    </BrowserRouter>
  )
}

export default App;
